import React from "react";

function AdminFooter() {
    return (
        <footer className="admin-footer">
            <p>Admin Footer</p>
        </footer>
    );
}

export default AdminFooter;
